<template>
  <v-container fluid>
    <UserAuthForm
      v-if="user"
      ref="userAuthForm"
      type="ForgotPassword"
      v-model="user"
      @submitForm="forgotPassword"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  components: {
    UserAuthForm,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    forgotPassword(user) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('forgotPassword', user)
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.reset();
          this.$router.push({ name: 'Login' });
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          console.log(error);
          console.log(error.response.data.msg);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    reset() {
      this.$refs.userAuthForm.reset;
      this.user = this.emptyUser();
    },
  },
  computed: {
    ...mapGetters(['emptyUser']),
  },
  mounted() {
    this.user = this.emptyUser();
  },
};
</script>

<style></style>
